//onlyOffice使用
export function getFileType(fileType) {
  //把所有带大写字母的转换成小写
  let swicthToLowerCase = fileType.toLocaleLowerCase();
  let docType = '';
  let fileTypesDoc = [
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'epub',
    'fodt',
    'htm',
    'html',
    'mht',
    'odt',
    'ott',
    'pdf',
    'rtf',
    'txt',
    'djvu',
    'xps'
  ];
  let fileTypesCsv = ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'];
  let fileTypesPPt = ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'];
  if (fileTypesDoc.includes(swicthToLowerCase)) {
    docType = 'word';
  }
  if (fileTypesCsv.includes(swicthToLowerCase)) {
    docType = 'cell';
  }
  if (fileTypesPPt.includes(swicthToLowerCase)) {
    docType = 'slide';
  }
  return docType;
}

//图片
export function isImageType(type) {
  //把所有带大写字母的转换成小写
  let swicthToLowerCase = type.toLocaleLowerCase();
  const imageType = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'webp']; //图片格式
  return imageType.indexOf(swicthToLowerCase) > -1;
}
//文件
export function isFileType(type) {
  //把所有带大写字母的转换成小写
  let swicthToLowerCase = type.toLocaleLowerCase();
  const fileType = [
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'epub',
    'fodt',
    'htm',
    'html',
    'mht',
    'odt',
    'ott',
    'pdf',
    'rtf',
    'djvu',
    'xps',
    'csv',
    'fods',
    'ods',
    'ots',
    'xls',
    'xlsm',
    'xlsx',
    'xlt',
    'xltm',
    'xltx',
    'fodp',
    'odp',
    'otp',
    'pot',
    'potm',
    'potx',
    'pps',
    'ppsm',
    'ppsx',
    'ppt',
    'pptm',
    'pptx'
  ]; //文件格式
  return fileType.indexOf(swicthToLowerCase) > -1;
}
//视频
export function isVideoType(type) {
  //把所有带大写字母的转换成小写
  let swicthToLowerCase = type.toLocaleLowerCase();
  const videoType = [
    'wmv',
    'asf',
    'asx',
    'rm',
    'rmvb',
    'mpg',
    'mpeg',
    'mpe',
    'mp4',
    'mp3',
    '3gp',
    'mov',
    'm4v',
    'avi',
    'dat',
    'mkv',
    'flv',
    'vob'
  ]; //视频格式
  return videoType.indexOf(swicthToLowerCase) > -1;
}
//文件下载方法
export function downLoadFile(fileId, fileName) {
  const a = document.createElement('a'); // 创建a标签
  const e = document.createEvent('MouseEvents'); // 创建鼠标事件对象
  e.initEvent('click', false, false); // 初始化事件对象 */
  a.href = `/api/roses-pub-service/sysFileInfo/download?fileId=${fileId}`; // 设置下载地址
  a.download = fileName; // 设置下载文件名
  a.dispatchEvent(e);
}
