/*
 * @Author: your name
 * @Date: 2021-09-15 09:48:21
 * @LastEditTime: 2021-09-18 18:09:33
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \全过程\whole-process-web\src\mixins\uploadFileOperation.js
 */
import {
  isImageType,
  isFileType,
  isVideoType
} from 'assets/js/uploadFile';
export const previewFile = {
  methods: {
    base64Encode(str) {
      if (typeof window !== 'undefined' && typeof window.btoa === 'function' && typeof TextEncoder === 'function') {
        // 使用TextEncoder将字符串转换为UTF-8编码的Uint8Array  
        const encoder = new TextEncoder();
        const uint8Array = encoder.encode(str);

        // 将Uint8Array转换为Base64编码的字符串  
        return window.btoa(String.fromCharCode(...uint8Array));
      } else {
        throw new Error('Base64 encoding is not supported in this environment.');
      }
    },
    //预览方法
    previewFile(fileInfo) {

      /* 这里可能针对不同的人返回的文件id可能是id或者filedId */
      if (isImageType(fileInfo.fileSuffix)) {
        //图片预览
        window.open(`http://36.138.233.111:31330/roses-pub-service/sysFileInfo/preview?fileId=${fileInfo.fileId}`);
      } else if (isFileType(fileInfo.fileSuffix)) {
        //打开新页面预览(onlyOffice)
        let href = 'http://36.138.233.111:31335/onlinePreview?url=' + this.base64Encode(fileInfo.filePath)
        window.open(href, '_blank');
      } else if (fileInfo.fileSuffix === 'dwg') {
        // CAD
        if (!fileInfo.reviewFilePath) {
          this.$message.warning('该文件正在转码中，请稍后...');
          return
        }
        window.open(fileInfo.reviewFilePath, '_blank');
      } else if (isVideoType(fileInfo.fileSuffix)) {
        //视频预览
        window.open(fileInfo.filePath);
      } else {
        this.$message.warning('该文件格式不支持在线预览，请下载查看');
      }
    }
  }
};
//文件下载
export const downLoadFile = {
  methods: {
    downLoadFile(fileId, fileName) {
      console.log(fileId, fileName);
      const a = document.createElement('a'); // 创建a标签
      const e = document.createEvent('MouseEvents'); // 创建鼠标事件对象
      e.initEvent('click', false, false); // 初始化事件对象 */
      a.href = `/api/roses-pub-service/sysFileInfo/download?fileId=${fileId}`; // 设置下载地址
      a.download = fileName; // 设置下载文件名
      a.dispatchEvent(e);
    }
  }
};
